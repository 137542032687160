import React from "react"

import { useI18n } from "next-localization"

import { Modal } from "@app/ui/modal"
import { ProductsSection } from "@app/features/products/components/products-section"
import { productsModalActions } from "@app/features/products/hoc/products-modal-actions"
import { PRODUCTS_MODAL_TYPES } from "@app/features/products/types"

import { Back } from "@app/ui/back"

import styles from "./products-modal.module.scss"

/**
 * Компонент отображающий список товаров печатной продукции в модальном окне
 *
 * @component
 * @example
 * return (
 *   <ProductsModal isVisible={true} hide={() => setIsVisible(false)} />
 * )
 */
const ProductsModal = ({ isVisible, hide }): JSX.Element => {
  const { t } = useI18n()

  return (
    <Modal
      isCrossIconVisible={false}
      open={isVisible}
      onClose={() => hide()}
      wrapperClassName={styles["wrapper"]}
      modalClassName={styles["root"]}
    >
      <Back onClick={() => hide()} text="Назад" page="order" className={styles["back"]} />
      <div className={styles["content"]}>
        <ProductsSection onlyAvailable={false} position={"modal"} />
      </div>
    </Modal>
  )
}

export default productsModalActions(ProductsModal, PRODUCTS_MODAL_TYPES.DEFAULT)
